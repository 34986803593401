<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h1 class="page-title">ACT Test Explanations</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 pl-0 pr-0">
        <div class="card-box pl-1 pr-1 pt-2 pb-2 mb-1">
          <div class="table-responsive pl-3 pr-3 pt-1 pb-3 mt-1">
            <p>Please click the links below to view ACT test explanations.</p>
            <table
              class="table table-bordered table-striped table-hover text-center"
            >
              <tr class="text-light bg-primary">
                <th>No</th>
                <th>ACT Exam Name</th>
                <th>Test Explanations</th>
              </tr>
              <tr v-for="i in 83" :key="i">
                <td>{{ i }}</td>
                <td>December 2020 Form D03</td>
                <td class="td-section">
                  <a
                    href="/exam/start.php?type=english&amp;examid=202012&amp;mode=0"
                    >English</a
                  >
                  <a
                    href="/exam/start.php?type=math&amp;examid=202012&amp;mode=0"
                    >Math</a
                  >
                  <a
                    href="/exam/start.php?type=reading&amp;examid=202012&amp;mode=0"
                    >Reading</a
                  >
                  <a
                    href="/exam/start.php?type=science&amp;examid=202012&amp;mode=0"
                    >Science</a
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: "ACT - ACT Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
.act-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
}

.td-section a {
  margin-right: 15px;
}
.table th {
  color: white !important;
}
</style>
<style scoped src="@/views/act/assets/css/act.css"></style>
<style scoped src="@/views/act/assets/css/simplebar.css"></style>
<style scoped src="@/views/act/assets/css/app.min.css"></style>
